import { createContext, useContext, useState } from "react";
import { defaultPassData } from "../datas/defaultdata";

type termsType = number[] | never[];

interface ContextProps {
  alert: AlertType;
  setAlert: React.Dispatch<React.SetStateAction<AlertType>>;
  confirm: ConfirmType;
  setConfirm: React.Dispatch<React.SetStateAction<ConfirmType>>;
  stroreTerms: termsType;
  setStroreTerms: React.Dispatch<React.SetStateAction<termsType>>;
  passUserInfo: PassUserInfoType;
  setPassUserInfo: React.Dispatch<React.SetStateAction<PassUserInfoType>>;
  dormant: DormantType;
  setDormant: React.Dispatch<React.SetStateAction<DormantType>>;
  certificationType: string;
  setCertificationType: React.Dispatch<React.SetStateAction<string>>;
  usridx: number;
  setUsridx: React.Dispatch<React.SetStateAction<number>>;
  fcmPermission: boolean;
  setFcmPermission: React.Dispatch<React.SetStateAction<boolean>>;
  userInfo: UserInfo;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>;
}

export const GlocalContext = createContext<ContextProps>({
  alert: { open: false, title: "", text: "", onCheck: () => {} },
  setAlert: () => {},
  confirm: {
    open: false,
    title: "",
    text: "",
    onCheck: () => {},
    onCancel: () => {},
  },
  setConfirm: () => {},
  stroreTerms: [],
  setStroreTerms: () => {},
  passUserInfo: defaultPassData,
  setPassUserInfo: () => {},
  dormant: {
    usridx: 0,
    usrname: "",
    usremail: "",
    joindate: "",
    logindate: "",
    dormantdate: "",
  },
  setDormant: () => {},
  certificationType: "none",
  setCertificationType: () => {},
  usridx: 0,
  setUsridx: () => {},
  fcmPermission: false,
  setFcmPermission: () => {},
  userInfo: {
    isauto: false,
    islife: false,
    lifedate: null,
    usrbirth: "",
    usremail: "",
    usrgender: "",
    usridx: 0,
    usrname: "",
    usrnickname: "",
    usrphoneno: "",
  },
  setUserInfo: () => {},
});

export const GlocalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [alert, setAlert] = useState({
    open: false,
    title: "",
    text: "",
    onCheck: () => {},
  });
  const [confirm, setConfirm] = useState({
    open: false,
    title: "",
    text: "",
    onCheck: () => {},
    onCancel: () => {},
  });
  const [stroreTerms, setStroreTerms] = useState<termsType>([]);
  const [passUserInfo, setPassUserInfo] =
    useState<PassUserInfoType>(defaultPassData);
  const [dormant, setDormant] = useState<DormantType>({
    usridx: 0,
    usrname: "",
    usremail: "",
    joindate: "",
    logindate: "",
    dormantdate: "",
  });
  const [usridx, setUsridx] = useState(0);
  const [customeridx, setCustomeridx] = useState(0);
  const [certificationType, setCertificationType] = useState("none");

  const [fcmPermission, setFcmPermission] = useState(false);

  const [userInfo, setUserInfo] = useState({
    isauto: false,
    islife: false,
    lifedate: null,
    usrbirth: "",
    usremail: "",
    usrgender: "",
    usridx: 0,
    usrname: "",
    usrnickname: "",
    usrphoneno: "",
  });

  return (
    <GlocalContext.Provider
      value={{
        alert,
        setAlert,
        confirm,
        setConfirm,
        stroreTerms,
        setStroreTerms,
        passUserInfo,
        setPassUserInfo,
        dormant,
        setDormant,
        certificationType,
        setCertificationType,
        usridx,
        setUsridx,
        fcmPermission,
        setFcmPermission,
        userInfo,
        setUserInfo,
      }}
    >
      {children}
    </GlocalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlocalContext);
