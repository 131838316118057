import { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { routerData, noNavRouterData } from "datas/nav";
import Navigator from "components/nav/Navigator";
import { useGlobalContext } from "context/store";
import Pass from "components/pass/Pass";
import { useTranslation } from "react-i18next";
import { handleNaitve } from "utils/native";
import { useMutation } from "@tanstack/react-query";
import {
  requestFcmUser,
  requestDeviceId,
  requestCheckToken,
} from "./api/api.ts";
import Link from "pages/link/Link";
import ViewForTablet from "pages/report/ViewForTablet";

const Router = () => {
  const { t } = useTranslation();
  const [deviceId, setDeviceId] = useState("");
  const [fcmToken, setFcmToken] = useState("");
  const [fcmLink, setFcmLink] = useState("");

  const { usridx, setCustomeridx, setPassUserInfo, setFcmPermission } =
    useGlobalContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("message", handlePostmessage);

    const mobileType = navigator.userAgent.toLowerCase();

    if (mobileType.indexOf("android") > -1) {
      localStorage.setItem("headerType", "60px"); //"20px"
    } else if (
      mobileType.indexOf("iphone") > -1 ||
      mobileType.indexOf("ipad") > -1 ||
      mobileType.indexOf("ipod") > -1 ||
      mobileType.indexOf("mac") > -1
    ) {
      localStorage.setItem("headerType", "60px");
    } else {
      localStorage.setItem("headerType", "60px");
    }

    window.UnityBackButton = UnityBackButton;
    window.UnityLog = UnityLog;
    window.UnityDeviceId = UnityDeviceId;
    window.UnityFCMToken = UnityFCMToken;
    window.UnityFCMPermission = UnityFCMPermission;
    window.UnityFCMData = UnityFCMData;

    return () => {
      window.removeEventListener("message", handlePostmessage);
      window.UnityBackButton = null;
      window.UnityLog = null;
      window.UnityDeviceId = null;
      window.UnityFCMToken = null;
      window.UnityFCMPermission = null;
      window.UnityFCMData = null;
    };
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [window.location.pathname]);

  const handlePostmessage = function (e) {
    const backpathname = localStorage.getItem("backpathname");
    const eventData = e?.data;

    if (
      eventData &&
      typeof eventData === "string" &&
      eventData?.includes("{") &&
      JSON.parse(eventData)
    ) {
      const data = JSON.parse(eventData);
      const {
        authidx,
        usrname,
        usrbirth,
        usrgender,
        usrphoneno,
        result,
        userinfo,
      } = data;

      if (result === "success") {
        if (
          authidx &&
          (userinfo?.iswithdrawal === true || userinfo?.iswithdrawal === "true")
        ) {
          navigate(backpathname);
          return alert(t("Mobile_Join_IdentityVerification_Err_Text_1_0"));
        }

        setPassUserInfo({
          authidx: authidx,
          usrname: usrname,
          usrbirth: usrbirth,
          usrgender: usrgender,
          usrphoneno: usrphoneno,
          userinfo: {
            iswithdrawal: userinfo?.iswithdrawal || false,
            usridx: userinfo?.usridx || "",
            usrname: userinfo?.usrname || "",
            joindate: userinfo?.joindate || "",
            usremail: userinfo?.usremail || "",
          },
        });

        navigate(backpathname);
      }
    }
  };

  const requestCheckFcmToken = useMutation({
    mutationFn: (param) => requestCheckToken(param),
    onSuccess: (data) => {
      if (data.result === "success") {
        if (data.token !== fcmToken) {
          postDeviceId.mutate({
            token: fcmToken,
            deviceid: deviceId,
          });
        }
      } else if (data.result === "failed") {
        postDeviceId.mutate({
          token: fcmToken,
          deviceid: deviceId,
        });
      }
    },
  });

  const postFcmUser = useMutation({
    mutationFn: (param) => requestFcmUser(param),
  });

  const postDeviceId = useMutation({
    mutationFn: (param) => requestDeviceId(param),
  });

  const UnityDeviceId = (deviceid) => {
    if (window?.Unity) {
      if (deviceid) {
        setDeviceId(deviceid);
      }
    }
  };

  const UnityCustomerIdx = (data) => {
    if (window?.Unity) {
      const customerIdx = JSON.parse(data);

      if (customerIdx) {
        setCustomeridx(Number(customerIdx));
      }
    }
  };

  const UnityFCMToken = (token) => {
    if (window?.Unity) {
      if (token) {
        setFcmToken(token);
      }
    }
  };

  const UnityFCMData = (data) => {
    if (window?.Unity) {
      const fcmData = JSON.parse(data);

      if (fcmData?.eblink) {
        setFcmLink(fcmData?.eblink);
      }
    }
  };

  const UnityBackButton = () => {
    if (window?.Unity) {
      if (window.location.pathname === "/") {
        handleNaitve(`{"cmd":"backstate", "value":"end"}`);
      } else {
        navigate(-1);
      }
    }
  };

  const UnityLog = () => {
    if (window?.Unity) {
      console.log("UnityLog");
    }
  };

  const UnityFCMPermission = (data) => {
    if (window?.Unity) {
      if (data === "allowed") {
        localStorage.setItem("fcmPermission", "allowed");
        setFcmPermission(true);
      } else if (data === "denied") {
        localStorage.setItem("fcmPermission", "denied");
        setFcmPermission(false);
      }
    }
  };

  useEffect(() => {
    if (window?.Unity) {
      handleNaitve(`{"cmd":"webview_loaded"}`);
    }
  }, []);

  useEffect(() => {
    if (fcmLink) {
      navigate("/link", { state: fcmLink });
    }
  }, [fcmLink]);

  useEffect(() => {
    if (deviceId && fcmToken) {
      requestCheckFcmToken.mutate({
        deviceid: deviceId,
      });
    }
  }, [fcmToken, deviceId]);

  useEffect(() => {
    if (usridx !== 0 && deviceId) {
      postFcmUser.mutate({
        usridx: usridx.toString(),
        deviceid: deviceId,
      });
    }
  }, [usridx, deviceId]);

  useEffect(() => {
    const reportRemoveMode = sessionStorage.getItem("reportRemoveMode");

    if (reportRemoveMode === "true" && !pathname.includes("report")) {
      sessionStorage.setItem("reportRemoveMode", JSON.stringify(false));
    }
  }, [pathname]);

  return (
    <div
      className={`routerElement`}
      style={{ minHeight: pathname.includes("jsp") ? "auto" : "100vh" }}
    >
      {!window.location.origin.includes("app.exbody.metafamily.world") &&
        !window.location.origin.includes("qa") && (
          <p className="webState">Dev</p>
        )}
      {window.location.origin.includes("qa") && (
        <p className="webState">QA 2</p>
      )}

      <Routes>
        <Route exact path="/" element={<Navigator />}>
          {routerData.map((data) => (
            <Route
              key={data.path}
              path={data.path}
              element={<div className={`element`}>{data.element}</div>}
            ></Route>
          ))}
          <Route path="/link" element={<Link />}></Route>
        </Route>
        {noNavRouterData.map((data) => (
          <Route
            key={data.path}
            path={data.path}
            element={<div className={`element`}>{data.element}</div>}
          ></Route>
        ))}
        {/* </Route> */}
        <Route path="*" element={<></>}></Route>
        <Route path="/jsp/kmc2" element={<Pass />}></Route>
        <Route path="/tabletreport" element={<ViewForTablet />}></Route>
      </Routes>
    </div>
  );
};

export default Router;
