import { QueryClient } from "@tanstack/react-query";
import { t } from "i18next";

type AnyOBJ = { [key: string]: any };

export const TEST_URL = "http://172.16.30.12:59882/";
export const DEV_URL = "https://metagymdev.exbody.com:59881/"; //60004
// export const DEV_URL = "https://metagymdev.exbody.com:49892/v1/"
export const LIVE_URL = "/"; //"/v1/"
export const QA_URL = "/"; //"/v1/" //"https://app.exbody.qa.metafamily.world/v1/"

export const BASE_URL = //"https://app.exbody.qa.metafamily.world/v1/"
  typeof window !== "undefined" &&
  window?.location.origin.includes("exbody.metafamily.world")
    ? LIVE_URL
    : window?.location.origin.includes("qa")
    ? QA_URL
    : DEV_URL;

export const getClient = (() => {
  let client: QueryClient | null = null;
  return () => {
    if (!client)
      client = new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 0,
            gcTime: Infinity,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            enabled: false,
          },
        },
      });
    return client;
  };
})();

export const restfetcher = async ({
  method,
  path,
  params,
}: {
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  path: string;
  params?: AnyOBJ;
}) => {
  try {
    const token =
      typeof sessionStorage !== "undefined"
        ? sessionStorage?.getItem("token")
        : null;
    let url = path.includes("http") ? path : `${BASE_URL}${path}`;
    const fetchOptions: any = {
      method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      fetchOptions.headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }

    if (params) fetchOptions.body = JSON.stringify(params);
    const res = await fetch(url, fetchOptions);
    const json = await res?.json();

    if (!res.ok || json?.errcode === 401) {
      if (res.status === 401 || (res.status === 200 && token)) {
        const refresh = localStorage?.getItem("refresh");
        const fetchOptionsToken: any = {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        if (refresh && token) {
          fetchOptionsToken.body = JSON.stringify({ token: refresh });
          const refreshedToken = await fetch(
            `${BASE_URL}v1/auth/token`,
            fetchOptionsToken
          );
          const refreshedTokenJson = await refreshedToken?.json();
          const authenticatetoken = refreshedTokenJson?.authenticatetoken;

          if (authenticatetoken) {
            const { jwt, refresh } = authenticatetoken;
            if (refreshedToken && jwt && refresh) {
              if (typeof sessionStorage !== "undefined") {
                sessionStorage?.setItem("token", jwt);
                localStorage?.setItem("refresh", refresh);
              }
              fetchOptions.headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
              };
              const refreshedResponse = await fetch(url, fetchOptions);
              const refreshedResult = await refreshedResponse.json();
              // setData(refreshedResult);
              return refreshedResult;
            } else {
              if (refreshedTokenJson.status === 403 || !jwt) {
                if (typeof sessionStorage !== "undefined") {
                  sessionStorage?.removeItem("token");
                  localStorage?.removeItem("refresh");
                }

                if (window && window.location) {
                  window.location.pathname = "/";
                }
                alert(t("Mobie_ServerErr_6_Pop_0"));
              }
            }
          } else {
            if (typeof sessionStorage !== "undefined") {
              sessionStorage?.removeItem("token");
              localStorage?.removeItem("refresh");
            }

            if (window && window.location) {
              window.location.pathname = "/";
            }
            alert(t("Mobie_ServerErr_7_Pop_0"));
          }
        } else {
          if (typeof sessionStorage !== "undefined") {
            sessionStorage?.removeItem("token");
            localStorage?.removeItem("refresh");
          }

          if (window && window.location) {
            window.location.pathname = "/";
          }
          alert(t("Mobie_ServerErr_7_Pop_0"));
        }
      } else {
        console.log(res.status);
      }
      throw new Error(`Error! status: ${res.status}`);
    } else {
      return json;
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchCookie = async () => {
  try {
    const token =
      typeof sessionStorage !== "undefined"
        ? sessionStorage?.getItem("token")
        : null;
    let headers: any = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }
    const response = await fetch(`${BASE_URL}v1/auth/token`, {
      method: "POST",
      credentials: "include",
      headers: headers,
      body: JSON.stringify({}),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error fetching cookie:", error);
  }
};

export const QueryKeys = {
  REPORTALL: "REPORTALL",
  RECOMMEND: "RECOMMEND",
  BANNERLOGOUT: "BANNERLOGOUT",
  BANNERLOGIN: "BANNERLOGIN",
  LOGIN: "LOGIN",
  USERPRIVACY: "USERPRIVACY",
  USERUPDATELOGIN: "USERUPDATELOGIN",
  DUPLICATECHECKEMAIL: "DUPLICATECHECKEMAIL",
  DUPLICATECHECKNICKNAME: "DUPLICATECHECKNICKNAME",
  DUPLICATECHECKPHONE: "DUPLICATECHECKPHONE",
  UPDATEEMAIL: "UPDATEEMAIL",
  UPDATENICKNAME: "UPDATENICKNAME",
  LOGOUT: "LOGOUT",
  WITHDRAWAL: "WITHDRAWAL",
  JOINUSER: "JOINUSER",
  UPDATEUSERPW: "UPDATEUSERPW",
  CHECKUPDATEPW: "CHECKUPDATEPW",
  UNLOCK: "UNLOCK",
  UPDATEPHONE: "UPDATEPHONE",
  AUTHTOKEN: "AUTHTOKEN",
  KEMAMOVIETAB: "KEMAMOVIETAB",
  KEMALIST: "KEMALIST",
  HEALTHMOVIES: "HEALTHMOVIES",
  SMSSEND: "SMSSEND",
  SMSMATCH: "SMSMATCH",
  UPDATENAME: "UPDATENAME",
  UPDATEBIRTH: "UPDATEBIRTH",
  UPDATEGENDER: "UPDATEGENDER",
  ISJOIN: "ISJOIN",
  NATION_LIST: "NATION_LIST",
  TABLET_REPORT: "TABLET_REPORT",
};
