import { useLayoutEffect } from "react";
import style from "./Report.module.scss";
import Header from "components/header/Header";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { QueryKeys, restfetcher } from "utils/queryClient";

const ViewForTablet = () => {
  const headerType = localStorage.getItem("headerType");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const usrIdx = Number(urlParams.get("usridx")) || 0;
  const customeridx = Number(urlParams.get("customeridx")) || 0;
  const programidx = Number(urlParams.get("programidx")) || 0;
  const measurementidx = urlParams.get("measurementidx") || "";

  const requestaReportListForTablet = useQuery({
    queryKey: [
      QueryKeys.TABLET_REPORT,
      customeridx,
      programidx,
      measurementidx,
    ],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/remote/report/list",
        params: {
          usridx: usrIdx,
          customeridx: customeridx,
          programidx: programidx,
          measurementidx: measurementidx,
        },
      }),
    enabled: customeridx !== 0 && programidx !== 0,
    placeholderData: keepPreviousData,
  });

  const handleClickCloseViewer = () => {
    window.history.back();
  };

  const reportfileList: string[] =
    requestaReportListForTablet?.data?.reportfileList || [];

  useLayoutEffect(() => {
    const viewport = document.getElementById("viewport");
    if (viewport) {
      viewport.setAttribute("content", "width=device-width, initial-scale=1");
    }

    return () => {
      if (viewport) {
        viewport.setAttribute(
          "content",
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        );
      }
    };
  }, []);

  return (
    <div
      className={`${style.view} openUp`}
      style={{ paddingTop: `${headerType === "60px" ? "101px" : "61px"}` }}
    >
      <Header
        title={"Mobile_Report_Page_Title_0"}
        isClose={false}
        onClick={handleClickCloseViewer}
        select={undefined}
        isBack={false}
        isTablet={true}
      />
      <div
        className={style.viewArea}
        style={{ height: `calc(100vh - ${headerType})` }}
      >
        {reportfileList?.map((url, index) => (
          <img src={url} alt="report" key={`report_${index}`} />
        ))}
      </div>
    </div>
  );
};

export default ViewForTablet;
